import Vue from 'vue'
import * as exp from 'exceptionless'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

exp.ExceptionlessClient.default.config.apiKey = process.env.VUE_APP_EXCEPTIONLESS_API!
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
