/* eslint-disable max-len */
/* eslint-disable no-irregular-whitespace */
import axios from 'axios'
import { PromotionCode } from '@/modules/signup'
import {
  ShippingCenter,
  ProteinType,
  ShippingEligiblity,
  ShippingEligibiltyResult,
  Item,
  CommonItemConfiguration,
  CustomItemConfiguration,
  SubscriberStatus,
  Contact,
  ShippingAddress,
  BillingInformation,
  SignupResult,
  SubscriptionConfiguration
} from '@/modules/common'
import validator from 'validator'
import { SignupStateData } from '@/store/modules/signup/types'
import { sortBy } from 'lodash'

export async function GetItems(): Promise<Array<Item>> {
  const result = await axios.get<Array<Item>>('/api/v0/common/get-items')
  return result.data
}

export async function GetCommonItemConfigurations(): Promise<Array<CommonItemConfiguration>> {
  const result = await axios.get<Array<CommonItemConfiguration>>('/api/v0/signup/get-common-item-configurations')
  return result.data
}

export async function PostalCodeLookup(input: {
  postalCode: string
  isGift: boolean
}): Promise<ShippingEligibiltyResult> {
  const result = await axios.post<ShippingEligibiltyResult>('/api/v0/signup/shipping-eligiblity', input)
  return result.data
}

export async function ConfirmShippingInformation(input: {
  emailAddress: string
  postalCode: string
}): Promise<ShippingEligibiltyResult> {
  if (input.emailAddress && validator.isEmail(input.emailAddress)) {
    const result = await axios.post<ShippingEligibiltyResult>('/api/v0/signup/shipping-eligiblity', input)
    return result.data
  }
  return {
    shippingEligiblity: ShippingEligiblity.Unknown,
    shippingCenter: ShippingCenter.Unknown,
    numberOfShippingDays: 0,
    city: '',
    state: '',
    shippingWeeks: [],
    postalCode: input.postalCode
  }
}

export async function ConfirmShippingInformationNoEmail(input: {
  postalCode: string
  isGift: boolean
}): Promise<ShippingEligibiltyResult> {
  const result = await axios.post<ShippingEligibiltyResult>('/api/v0/signup/shipping-eligiblity', input)
  return result.data
}

export async function ConfirmPromotionCode(code: string): Promise<PromotionCode> {
  const result = await axios.get<PromotionCode>(`/api/v0/signup/get-promotion-code-info/${code}`)
  return result.data
}

export async function GetCustomItemConfiguration(input: {
  proteinList: Array<ProteinType>
  shippingCenter: ShippingCenter
}): Promise<CustomItemConfiguration> {
  const result = await axios.get<CustomItemConfiguration>(
    `/api/v0/signup/get-custom-item-configuration/${input.shippingCenter}/${sortBy(input.proteinList).join('')}`
  )
  return result.data
}

export async function GetActiveCart(cartId: string): Promise<SignupStateData> {
  const result = await axios.post<SignupStateData>('/api/v0/signup/get-cart/', { cartId })
  return result.data
}

export async function SaveActiveCart(input: {
  cartId: string
  emailAddress: string
  cartPayload: string
  timestamp: number
}) {
  await axios.post('/api/v0/signup/save-cart', input)
}

export async function RemoveCompletedCart(input: { cartId: string; emailAddress: string }) {
  await axios.post('/api/v0/signup/delete-completed-cart', input)
}

export async function AddNewsEmail(input: { emailAddress: string }) {
  await axios.post('/api/v0/common/join-newsletter', input)
}

export async function ProcessSignup(input: {
  customerContactInfo: Contact
  shippingAddress: ShippingAddress
  subscriptionConfiguration: SubscriptionConfiguration
  billingInformation: BillingInformation
  stripeToken: string
  promotionCode: string | undefined
  password: string
  thirdPartyProvider: string
  thirdPartyId: string
  firstShipmentDate: Date
  overrideShippingAddress: boolean
  selectedItems: Array<{ itemId: string; maxSwapPrice: number }>
  originalSubscriptionId: string
}): Promise<SignupResult> {
  if (input.originalSubscriptionId && input.originalSubscriptionId !== '') {
    const result = await axios.post<SignupResult>('/api/v0/signup/reactivate', input)
    return result.data
  }
  const result = await axios.post<SignupResult>('/api/v0/signup', input)
  return result.data
}

export async function GetResubscribeInfo(input: {
  subscriptionId: string
}): Promise<{ customerContactInfo: Contact; shippingAddress: ShippingAddress }> {
  const result = await axios.post<any>('/api/v0/signup/retrieve-subscriber-info', input)
  return result.data
}

export async function GetEmptyUsername(): Promise<SubscriberStatus> {
  return SubscriberStatus.None
}
export async function GetEmptyShippingResult(): Promise<ShippingEligibiltyResult> {
  return {
    city: '',
    state: '',
    shippingCenter: ShippingCenter.Unknown,
    postalCode: '',
    shippingEligiblity: ShippingEligiblity.Unknown,
    numberOfShippingDays: 0,
    shippingWeeks: []
  }
}
export async function GetUsernameStatus(input: { username: string }): Promise<SubscriberStatus> {
  if (input.username && validator.isEmail(input.username)) {
    const result = await axios.post<SubscriberStatus>('/api/v0/signup/get-account-status-by-username/', input)
    return result.data
  }
  return SubscriberStatus.Unknown
}
