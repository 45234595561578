























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import WebFont from 'webfontloader'
import StepNavigation from '@/components/signup/StepNavigation/StepNavigation.vue'
import CommonModule from '@/store/modules/common'
import { getModule } from 'vuex-module-decorators'
import LogRocket from 'logrocket'
import DiscountBanner from '@/components/signup/DiscountBanner/Index.vue'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
if (process.env.VUE_APP_LOGROCKET_KEY! !== 'NONE') {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY!)
}

WebFont.load({
  custom: {
    families: ['icomoon'],
    urls: ['/apps/fonts.css']
  }
})

@Component({
  components: {
    StepNavigation,
    DiscountBanner
  }
})
export default class App extends Vue {
  commonModule: CommonModule = getModule(CommonModule, this.$store)

  showSpacer = false

  // eslint-disable-next-line class-methods-use-this
  get inShopping() {
    return window.location.pathname.startsWith('/apps/shopping')
  }

  get quickAccessTitle() {
    return this.commonModule.quickAccessTitle
  }

  get toolBarHeight() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return null
    }
    return `${100}px`
  }
}
