import {
  Contact,
  ShippingAddress,
  BillingInformation,
  SubscriptionConfiguration,
  LegacyPackItem
} from '../common'

export enum SignupStatus {
    Unknown = 0,
    Inprogress = 1,
    Completed = 2,
    BillingError = 3,
    UnknownError = 4
}

export interface PromotionCode {
    code: string;
    isValid: boolean;
    dollarAmount: number;
    percentageAmount: number;
    discountDuration: number;
    freeProductDuration: number;
    freeProducts: Array<LegacyPackItem>;
    messaging: string;
}

export interface Signup {
    customerContactInfo: Contact;
    shippingAddress: ShippingAddress;
    billingContact: BillingInformation;
    subscriptionConfiguration: SubscriptionConfiguration;
    firstShipmentDate: Date | undefined;
    overrideShippingAddress: boolean | undefined;
    promotionCode: PromotionCode;
    signupFirstSeen: Date | undefined;
    signupLastSeen: Date | undefined;
    signupCompleted: Date | undefined;
    password: string | undefined;
    thirdPartyCredentials: { id: string; provider: string };
}
