/* eslint class-methods-use-this: ["error", { "exceptMethods": ["configureSignup"] }] */
import { VuexModule, Module, Action, Mutation, MutationAction } from 'vuex-module-decorators'
import * as api from '@/apis/signup'
import LogRocket from 'logrocket'
import store from '@/store'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import map from 'lodash/map'
import Cookie from 'js-cookie'

import {
  SubscriberStatus,
  ShippingCenter,
  ProteinType,
  ShippingEligiblity,
  LegacyPackItem,
  Item,
  ShippingEligibiltyResult,
  CommonItemConfiguration,
  ShellfishCheck,
  PackSize,
  Frequency,
  SignupResult
} from '@/modules/common'
import { SignupStatus, PromotionCode, Signup } from '@/modules/signup'
import shortid from 'shortid'
import { parseFullName } from 'parse-full-name'
import validator from 'validator'
import { SignupStateData } from './types'

@Module({
  dynamic: true,
  store,
  name: 'signup'
})
export default class SignupModule extends VuexModule implements SignupStateData {
  signupId = ''

  existingSubscriptionId = ''

  restoredFromSessionState = false

  itemsAttachedToConifgs = false

  token = ''

  subscriberStatus: SubscriberStatus = SubscriberStatus.Unknown

  shippingEligiblity: ShippingEligiblity = ShippingEligiblity.Unknown

  shippingCenter: ShippingCenter = ShippingCenter.Unknown

  numberOfShippingDays = 0

  orderId = ''

  lastCartHash = ''

  isProcessingOrder = false

  shippingWeeks: Array<Date> = []

  signupInfo: Signup = {
    billingContact: {
      firstName: '',
      lastName: '',
      billingAddress: {
        streetAddressLine1: '',
        streetAddressLine2: undefined,
        city: '',
        state: '',
        postalCode: ''
      }
    },
    customerContactInfo: {
      emailAddress: '',
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined
    },
    firstShipmentDate: undefined,
    overrideShippingAddress: false,
    password: undefined,
    thirdPartyCredentials: { id: '', provider: '' },
    promotionCode: {
      code: '',
      isValid: false,
      dollarAmount: 0,
      discountDuration: 0,
      freeProducts: [],
      percentageAmount: 0,
      freeProductDuration: 0,
      messaging: ''
    },
    shippingAddress: {
      address: {
        streetAddressLine1: '',
        streetAddressLine2: undefined,
        city: '',
        state: '',
        postalCode: ''
      },
      specialInstructions: undefined
    },
    signupCompleted: undefined,
    signupFirstSeen: undefined,
    signupLastSeen: undefined,
    subscriptionConfiguration: {
      proteinTypes: [],
      frequency: Frequency.Unknown,
      packSize: PackSize.Unknown
    }
  }

  signupStatus: SignupStatus = SignupStatus.Unknown

  errorMessage = ''

  commonItemConfigurations: Array<CommonItemConfiguration> = []

  selectedItems: Array<LegacyPackItem> = []

  selectedProteins: Array<ProteinType> = []

  shellfishAlergyStatus: ShellfishCheck = ShellfishCheck.Unknown

  selectedCommonItemConfiguration: CommonItemConfiguration = {
    id: '',
    description: '',
    displayOrder: -1,
    items: [],
    name: '',
    proteins: [],
    shippingCenter: ShippingCenter.Unknown,
    isReadonly: false
  }

  items: Array<Item> = []

  // eslint-disable-next-line class-methods-use-this
  get cleanState(): SignupStateData {
    return {
      signupId: '',

      existingSubscriptionId: '',

      orderId: '',

      subscriberStatus: SubscriberStatus.Unknown,

      shippingEligiblity: ShippingEligiblity.Unknown,

      shippingCenter: ShippingCenter.Unknown,

      numberOfShippingDays: 0,

      signupInfo: {
        billingContact: {
          firstName: '',
          lastName: '',
          billingAddress: {
            streetAddressLine1: '',
            streetAddressLine2: undefined,
            city: '',
            state: '',
            postalCode: ''
          }
        },
        customerContactInfo: {
          emailAddress: '',
          firstName: undefined,
          lastName: undefined,
          phoneNumber: undefined
        },
        firstShipmentDate: undefined,
        overrideShippingAddress: false,
        password: undefined,
        thirdPartyCredentials: { id: '', provider: '' },
        promotionCode: {
          code: '',
          isValid: false,
          dollarAmount: 0,
          discountDuration: 0,
          freeProducts: [],
          percentageAmount: 0,
          freeProductDuration: 0,
          messaging: ''
        },
        shippingAddress: {
          address: {
            streetAddressLine1: '',
            streetAddressLine2: undefined,
            city: '',
            state: '',
            postalCode: ''
          },
          specialInstructions: undefined
        },
        signupCompleted: undefined,
        signupFirstSeen: undefined,
        signupLastSeen: undefined,
        subscriptionConfiguration: {
          proteinTypes: [],
          frequency: Frequency.Unknown,
          packSize: PackSize.Unknown
        }
      },

      signupStatus: SignupStatus.Unknown,

      errorMessage: '',

      commonItemConfigurations: [],

      selectedItems: [],

      selectedProteins: [],

      shellfishAlergyStatus: ShellfishCheck.Unknown,

      selectedCommonItemConfiguration: {
        id: '',
        description: '',
        displayOrder: -1,
        items: [],
        name: '',
        proteins: [],
        shippingCenter: ShippingCenter.Unknown,
        isReadonly: false
      }
    }
  }

  get hasValidPromotionCode() {
    return this.signupInfo.promotionCode.isValid
  }

  get HasGettingStartedErrors() {
    return this.errorMessage.length > 0 && !this.ReadyToSignUp
  }

  get HasSignupErrors() {
    return this.errorMessage.length && this.ReadyToSignUp
  }

  get HasInvalidZipCode() {
    return (
      this.shippingEligiblity === ShippingEligiblity.Unknown &&
      this.signupInfo.shippingAddress.address.postalCode.length === 5
    )
  }

  get CannotShip() {
    return this.shippingEligiblity === ShippingEligiblity.Ineligible
  }

  get HasExistingAccountWithBillingIssue() {
    return this.subscriberStatus === SubscriberStatus.Suspended
  }

  get HasExistingAccountWithNoIssues() {
    return this.subscriberStatus === SubscriberStatus.Active
  }

  get HasExistingAccountThatCancelled() {
    return this.subscriberStatus === SubscriberStatus.Canceled
  }

  get hasKnownSubscriberId() {
    return this.existingSubscriptionId !== ''
  }

  get StripeTokenValues(): stripe.TokenOptions {
    const isUsingBilling = this.signupInfo.billingContact.billingAddress.postalCode.length === 5
    const token: stripe.TokenOptions = {
      name: `${this.signupInfo.billingContact.firstName} ${this.signupInfo.billingContact.lastName}`,

      address_line1: isUsingBilling
        ? this.signupInfo.billingContact.billingAddress.streetAddressLine1
        : this.signupInfo.shippingAddress.address.streetAddressLine1,

      address_line2: isUsingBilling
        ? this.signupInfo.billingContact.billingAddress.streetAddressLine2
        : this.signupInfo.shippingAddress.address.streetAddressLine2,

      address_city: isUsingBilling
        ? this.signupInfo.billingContact.billingAddress.city
        : this.signupInfo.shippingAddress.address.city,

      address_state: isUsingBilling
        ? this.signupInfo.billingContact.billingAddress.state
        : this.signupInfo.shippingAddress.address.state,

      address_zip: isUsingBilling
        ? this.signupInfo.billingContact.billingAddress.postalCode
        : this.signupInfo.shippingAddress.address.postalCode,

      address_country: 'USA',
      currency: 'usd'
    }

    return token
  }

  get CustomerWithinShippableArea() {
    if (this.shippingEligiblity === ShippingEligiblity.Eligible) {
      return true
    }
    if (this.shippingEligiblity === ShippingEligiblity.Ineligible) {
      return false
    }
    return null
  }

  get subscriptionRetailPrice(): number {
    if (this.signupInfo.subscriptionConfiguration.packSize === PackSize.Large) {
      return 329.98
    }
    return 169.99
  }

  get discountedStandardPrice(): number {
    return 169.99 - this.discount
  }

  get discountedLargePrice(): number {
    return 329.98 - this.discount
  }

  // eslint-disable-next-line class-methods-use-this
  get standardPrice(): number {
    return 169.99
  }

  // eslint-disable-next-line class-methods-use-this
  get largePrice(): number {
    return 329.98
  }

  // eslint-disable-next-line class-methods-use-this
  get shippingPrice() {
    return '$0.00'
  }

  get discount() {
    if (this.signupInfo.promotionCode) {
      if (this.signupInfo.promotionCode.dollarAmount > 0) {
        return this.signupInfo.promotionCode.dollarAmount
      }
      if (this.signupInfo.promotionCode.percentageAmount > 0) {
        return this.subscriptionRetailPrice * this.signupInfo.promotionCode.percentageAmount
      }
    }
    return 0
  }

  get finalPrice() {
    return this.subscriptionRetailPrice - this.discount
  }

  get IsCommonItemConfigurationSelected() {
    return !validator.isEmpty(this.selectedCommonItemConfiguration.id)
  }

  get RequiresShellfishCheck() {
    if (
      this.selectedProteins.find(p => p === ProteinType.Fish) !== undefined &&
      this.shellfishAlergyStatus === ShellfishCheck.Unknown
    ) {
      return true
    }
    return false
  }

  get GroupedSelectItems() {
    const g = groupBy(
      this.SelectedItems.filter(p => p.freeItemId === undefined),
      p => p.id
    )

    const result = map(g, items => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      quantity: items[0].item!.quantity * items.length,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name: items[0].item!.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      size: items[0].item!.size,
      isFreeItem: false,
      displayOrder: orderBy(items, p => p.displayOrder)[0].displayOrder
    }))

    this.SelectedItems.filter(p => p.freeItemId !== undefined).forEach(p => {
      result.push({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        quantity: p.item!.quantity,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        name: p.item!.name,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        size: p.item!.size,
        isFreeItem: true,
        displayOrder: 99
      })
    })

    return sortBy(
      result.map(q => ({
        name: q.name,
        quantity: q.quantity,
        size: q.size,
        isFreeItem: q.isFreeItem,
        displayOrder: q.displayOrder
      })),
      x => x.displayOrder
    )
  }

  get SelectedItems() {
    return sortBy(this.selectedItems, [o => (o.freeItemId ? 1 : 0), p => p.displayOrder])
  }

  get SelecteItemsMinusAnyFree() {
    return this.SelectedItems.filter(p => p.freeItemId === undefined)
  }

  get SubstituteItemsByIndex() {
    return (index: number) => {
      if (this.SelectedItems[index].subsituteItems.length > 0) {
        const results = sortBy(
          this.SelectedItems[index].subsituteItems.filter(
            q =>
              q.item?.id !== this.SelectedItems[index].id &&
              q.item &&
              this.selectedProteins.indexOf(q.item.proteinType) !== -1
          ),
          p => p.displayOrder
        )
        return results
      }
      return []
    }
  }

  get hasCustomizedCommonConfiguration() {
    if (
      !this.selectedCommonItemConfiguration ||
      this.selectedCommonItemConfiguration.id === '' ||
      this.selectedItems.length === 0
    ) {
      return false
    }
    let changed = false

    this.selectedCommonItemConfiguration.items.forEach(p => {
      const index = this.selectedCommonItemConfiguration.items.indexOf(p)
      if (changed === false) {
        changed = p.id !== this.selectedItems[index].id
      }
    })

    return changed
  }

  get PackName() {
    if (this.selectedCommonItemConfiguration.id === '') {
      return sortBy(this.selectedProteins)
        .map(p => ProteinType[p])
        .join(', ')
    }
    return this.selectedCommonItemConfiguration.name
  }

  get PackId() {
    if (this.selectedCommonItemConfiguration.id === '') {
      return (
        (this.shippingCenter === ShippingCenter.EastCoastCenter2 ? 'H' : ShippingCenter.EastCoastCenter3 ? 'S' : 'C') + sortBy(this.selectedProteins).join('')
      )
    }
    return this.selectedCommonItemConfiguration.id
  }

  get PackDescription() {
    if (this.selectedCommonItemConfiguration.id === '') {
      return ''
    }
    return this.selectedCommonItemConfiguration.description
  }

  get DoesItemAllowSubstituesByIndex() {
    return (index: number) =>
      this.SelectedItems[index].subsituteItems && this.SelectedItems[index].subsituteItems.length > 0
  }

  get EligibleShippingWeeks() {
    return this.shippingWeeks
  }

  get PurchasableCommonConfigurations() {
    if (this.commonItemConfigurations) {
      return sortBy(
        filter(this.commonItemConfigurations, p => p.shippingCenter === ShippingCenter.EastCoastCenter2),
        q => q.displayOrder
      )
    }
    return []
  }

  get ReadyToSignUp() {
    return this.PurchasableCommonConfigurations.length === 3
  }

  get IsFaceBookConnected() {
    return this.signupInfo.thirdPartyCredentials.provider === 'fb'
  }

  @Mutation
  private SET_RestoredFromSessionState() {
    this.restoredFromSessionState = true
  }

  @Mutation
  private SET_ItemsAttachedToConfigs(value: boolean) {
    this.itemsAttachedToConifgs = value
  }

  @Mutation
  private SET_PackSize(packSize: PackSize) {
    this.signupInfo.subscriptionConfiguration.packSize = packSize
  }

  @Mutation
  private SET_Frequency(frequency: Frequency) {
    this.signupInfo.subscriptionConfiguration.frequency = frequency
  }

  @Mutation
  private SET_OrderComplete() {
    this.signupStatus = SignupStatus.Completed
  }

  @Mutation
  private SET_ShellfishCheck(check: ShellfishCheck) {
    this.shellfishAlergyStatus = check
  }

  @Mutation
  private ADD_ProteinSelection(protein: ProteinType) {
    if (this.selectedProteins.find(p => p === protein) === undefined) {
      this.selectedProteins.push(protein)
    }
  }

  @Mutation
  private SET_OrderId(value: string) {
    this.orderId = value
  }

  @Mutation
  private REMOVE_ProteinSelection(protein: ProteinType) {
    const index = this.selectedProteins.indexOf(protein)
    if (index > -1) {
      this.selectedProteins.splice(index, 1)
    }
  }

  @Mutation
  private ADD_ItemSelection(item: LegacyPackItem) {
    if (
      this.selectedItems.find(
        p =>
          p.id === item.id &&
          (p.freeItemId !== undefined || item.freeItemId !== undefined) &&
          p.freeItemId === item.freeItemId
      ) === undefined
    ) {
      const clone = { ...item }
      this.selectedItems.push(clone)
    }
  }

  @Mutation
  private REMOVE_ItemSelection(item: LegacyPackItem) {
    const index = this.selectedItems.findIndex(p => p.id === item.id)
    if (index > -1) {
      this.selectedItems.splice(index, 1)
    }
  }

  @Mutation
  private REMOVE_FreeItems() {
    let index = this.selectedItems.findIndex(p => p.freeItemId !== undefined)
    while (index > -1) {
      this.selectedItems.splice(index, 1)
      index = this.selectedItems.findIndex(p => p.freeItemId !== undefined)
    }
  }

  @Mutation
  private SET_Items(items: Array<Item>) {
    this.items.length = 0
    this.items.push(...items)
  }

  @Mutation
  private SET_CommonItemConfigurations(configs: Array<CommonItemConfiguration>) {
    this.commonItemConfigurations.length = 0
    this.commonItemConfigurations.push(...configs)
  }

  @Mutation
  private SET_SelectedCommonItemConfiguration(id: string) {
    const config = this.commonItemConfigurations.find(p => p.id === id)
    if (config) {
      this.selectedCommonItemConfiguration = config
    }
  }

  @Mutation
  private REMOVE_SelectedCommonItemConfiugration() {
    this.selectedCommonItemConfiguration = {
      id: '',
      description: '',
      displayOrder: -1,
      items: [],
      name: '',
      proteins: [],
      shippingCenter: ShippingCenter.Unknown,
      isReadonly: false
    }
  }

  @Mutation
  private SET_InitialShippingConfiguration() {
    this.shippingCenter = ShippingCenter.EastCoastCenter1
    this.shippingEligiblity = ShippingEligiblity.Eligible
  }

  @Mutation
  private SET_ShippingConfiguration(result: ShippingEligibiltyResult) {
    this.shippingCenter = result.shippingCenter
    this.shippingEligiblity = result.shippingEligiblity
    this.numberOfShippingDays = result.numberOfShippingDays
    this.shippingWeeks = result.shippingWeeks.map(p => new Date(p))
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/ban-types
  private RESET_DefaultState(state: object) {
    Object.assign(this, state)
  }

  @Mutation
  private SET_ShippingAddress(input: {
    postalCode: string
    state: string
    city: string
    streetAddressLine1: string
    streetAddressLine2: string
    specialInstructions: string
  }) {
    if (input.postalCode && input.postalCode.length === 5) {
      this.signupInfo.shippingAddress.address.postalCode = input.postalCode
    }
    if (input.state && input.state.length === 2) {
      this.signupInfo.shippingAddress.address.state = input.state
    }
    if (input.city && input.city.length > 0) {
      this.signupInfo.shippingAddress.address.city = input.city
    }
    if (input.streetAddressLine1 !== undefined) {
      this.signupInfo.shippingAddress.address.streetAddressLine1 = input.streetAddressLine1
    }
    if (input.streetAddressLine2 !== undefined) {
      this.signupInfo.shippingAddress.address.streetAddressLine2 = input.streetAddressLine2
    }
    if (input.specialInstructions !== undefined) {
      this.signupInfo.shippingAddress.specialInstructions = input.specialInstructions
    }
  }

  @Mutation REMOVE_BillingAddress() {
    this.signupInfo.billingContact.billingAddress.city = ''
    this.signupInfo.billingContact.billingAddress.state = ''
    this.signupInfo.billingContact.billingAddress.streetAddressLine1 = ''
    this.signupInfo.billingContact.billingAddress.streetAddressLine2 = ''
    this.signupInfo.billingContact.billingAddress.postalCode = ''
  }

  @Mutation SET_BillingContact(input: { firstName: string; lastName: string }) {
    this.signupInfo.billingContact.firstName = input.firstName
    this.signupInfo.billingContact.lastName = input.lastName
  }

  @Mutation
  private SET_BillingAddress(input: {
    postalCode: string
    state: string
    city: string
    streetAddressLine1: string
    streetAddressLine2: string
  }) {
    if (input.postalCode && input.postalCode.length === 5) {
      this.signupInfo.billingContact.billingAddress.postalCode = input.postalCode
    }
    if (input.state && input.state.length === 2) {
      this.signupInfo.billingContact.billingAddress.state = input.state
    }
    if (input.city && input.city.length > 0) {
      this.signupInfo.billingContact.billingAddress.city = input.city
    }
    if (input.streetAddressLine1 !== undefined) {
      this.signupInfo.billingContact.billingAddress.streetAddressLine1 = input.streetAddressLine1
    }
    if (input.streetAddressLine2 !== undefined) {
      this.signupInfo.billingContact.billingAddress.streetAddressLine2 = input.streetAddressLine2
    }
  }

  @Mutation
  private SET_CustomerContact(input: {
    emailAddress: string
    lastName: string
    firstName: string
    phoneNumber: string
  }) {
    if (input.emailAddress && validator.isEmail(input.emailAddress.trim())) {
      this.signupInfo.customerContactInfo.emailAddress = input.emailAddress.trim()
    }
    if (input.lastName && !validator.isEmpty(input.lastName)) {
      this.signupInfo.customerContactInfo.lastName = input.lastName
    }
    if (input.firstName && !validator.isEmpty(input.firstName)) {
      this.signupInfo.customerContactInfo.firstName = input.firstName
    }
    if (input.phoneNumber && validator.isMobilePhone(input.phoneNumber, 'en-US')) {
      this.signupInfo.customerContactInfo.phoneNumber = input.phoneNumber
    }

    if (validator.isEmail(this.signupInfo.customerContactInfo.emailAddress)) {
      LogRocket.identify(this.signupInfo.customerContactInfo.emailAddress)
      if (this.signupInfo.customerContactInfo.firstName && this.signupInfo.customerContactInfo.lastName) {
        LogRocket.identify(this.signupInfo.customerContactInfo.emailAddress, {
          name: `${this.signupInfo.customerContactInfo.firstName} ${this.signupInfo.customerContactInfo.lastName}`
        })
      }
    }
  }

  @Mutation SET_ShippingWeek(input: Date) {
    this.signupInfo.firstShipmentDate = input
  }

  @Mutation
  private SET_SignupCartViaRehydrate(input: SignupStateData) {
    this.selectedProteins.push(...input.selectedProteins)
    this.signupId = input.signupId
  }

  @Mutation
  private SET_SubstituteItem(input: { itemIndex: number; newItemId: string }) {
    const item = this.selectedItems[input.itemIndex]
    if (item) {
      item.id = input.newItemId
      item.item = this.items.find(p => p.id === input.newItemId)
    }
  }

  @Mutation
  private SET_SubscriberStatus(status: SubscriberStatus) {
    if (this.existingSubscriptionId && this.existingSubscriptionId !== '') {
      this.subscriberStatus = SubscriberStatus.None
    } else {
      this.subscriberStatus = status
    }
  }

  @Mutation
  private SET_ConfigureCommonConfigItems() {
    if (this.shippingEligiblity === ShippingEligiblity.Eligible) {
      this.commonItemConfigurations.forEach(p => {
        if (p.items) {
          p.items.forEach(q => {
            // eslint-disable-next-line no-param-reassign
            q.item = this.items.find(q1 => q1.id === q.id)
            if (q.subsituteItems) {
              q.subsituteItems.forEach(r => {
                // eslint-disable-next-line no-param-reassign
                r.item = this.items.find(r1 => r1.id === r.id)
              }, this)
            }
          }, this)
        }
      }, this)
    }
  }

  @Mutation
  private SET_SignupId(signupId: string) {
    this.signupId = signupId
  }

  @Mutation
  private SET_ExistingSubscriptionId(existingSubscriptionId: string) {
    this.existingSubscriptionId = existingSubscriptionId
  }

  @Mutation
  private SET_Password(password: string) {
    this.signupInfo.password = password
    if (password !== '' && password != null) {
      this.signupInfo.thirdPartyCredentials.id = ''
      this.signupInfo.thirdPartyCredentials.provider = ''
    }
  }

  @Mutation
  private SET_ThirdpPartyCredentials(input: { id: string; provider: string }) {
    if (input.provider !== '') {
      this.signupInfo.password = undefined
    }
    this.signupInfo.thirdPartyCredentials.id = input.id
    this.signupInfo.thirdPartyCredentials.provider = input.provider
  }

  @Mutation
  private SET_ErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage
  }

  @Mutation
  private SET_PromotionCode(promotionCode: PromotionCode) {
    if (this.signupInfo) {
      this.signupInfo.promotionCode = promotionCode
    }
  }

  @Mutation
  private REMOVE_EmailAddress() {
    this.signupInfo.customerContactInfo.emailAddress = ''
  }

  @Mutation SET_SelectedItems(items: Array<LegacyPackItem>) {
    this.selectedItems = items
  }

  @MutationAction({ mutate: ['selectedProteins'] })
  // eslint-disable-next-line class-methods-use-this
  async SetSelectedProteins(proteins: Array<ProteinType>) {
    return { selectedProteins: [...proteins] }
  }

  @MutationAction({ mutate: ['token'] })
  // eslint-disable-next-line class-methods-use-this
  async SetToken(token: string) {
    return { token }
  }

  @Action
  // eslint-disable-next-line class-methods-use-this
  async SetSelectedItems(items: Array<LegacyPackItem>) {
    items.forEach(q => {
      // eslint-disable-next-line no-param-reassign
      q.item = this.items.find(q1 => q1.id === q.id)
      if (q.subsituteItems) {
        q.subsituteItems.forEach(r => {
          // eslint-disable-next-line no-param-reassign
          r.item = this.items.find(r1 => r1.id === r.id)
        }, this)
      }
    }, this)

    const newItems: Array<LegacyPackItem> = []
    items.forEach(p => {
      newItems.push({
        id: p.id,
        freeItemId: p.freeItemId,
        item: p.item,
        maxSwapPrice: p.maxSwapPrice,
        shippingQuantity: 1,
        displayOrder: p.displayOrder,
        subsituteItems: p.subsituteItems
      })
    })
    this.context.commit('SET_SelectedItems', newItems)
  }

  @Action
  async SetShippingWeek(value: Date) {
    this.context.commit('SET_ShippingWeek', value)
  }

  @Action({ rawError: true })
  async SubstituteItem(input: { itemIndex: number; newItemId: string }) {
    const result = this.selectedItems.indexOf(this.SelectedItems[input.itemIndex])
    if (result > -1) {
      this.context.commit('SET_SubstituteItem', { itemIndex: result, newItemId: input.newItemId })
    }
  }

  @Action
  SetShellfishAllergey(status: ShellfishCheck) {
    if (
      status === ShellfishCheck.NoAllergey &&
      this.selectedProteins.find(p => p === ProteinType.Fish) !== undefined &&
      this.selectedProteins.find(p => p === ProteinType.Shellfish) === undefined
    ) {
      this.context.commit('SET_ShellfishCheck', ShellfishCheck.NoAllergey)
      this.context.commit('ADD_ProteinSelection', ProteinType.Shellfish)
    } else {
      this.context.commit('SET_ShellfishCheck', status)
      if (status === ShellfishCheck.Allergy) {
        this.context.commit('REMOVE_ProteinSelection', ProteinType.Shellfish)
      }
    }
  }

  @Mutation
  private SET_DoubleOrder() {
    const newItems: Array<LegacyPackItem> = []
    this.selectedItems.forEach(p => {
      newItems.push({
        id: p.id,
        freeItemId: p.freeItemId,
        item: p.item,
        shippingQuantity: 1,
        maxSwapPrice: p.maxSwapPrice,
        displayOrder: p.displayOrder + p.displayOrder / 10,
        subsituteItems: p.subsituteItems
      })
    })
    this.selectedItems.push(...newItems)
    this.signupInfo.subscriptionConfiguration.packSize = PackSize.Large
  }

  @Mutation
  private SET_SingleOrder() {
    const length = this.selectedItems.length / 2
    this.selectedItems.splice(length, length)
    this.signupInfo.subscriptionConfiguration.packSize = PackSize.Standard
  }

  @Action
  DoubleUp() {
    if (this.signupInfo.subscriptionConfiguration.packSize === PackSize.Standard) {
      if (this.signupInfo.promotionCode.isValid) {
        this.context.commit('REMOVE_FreeItems')
      }
      this.context.commit('SET_DoubleOrder')
      if (this.signupInfo.promotionCode.isValid) {
        this.ApplyAnyApplicablePromotionCodes()
      }
    }
  }

  @Action
  SingleDown() {
    if (this.signupInfo.subscriptionConfiguration.packSize === PackSize.Large) {
      if (this.signupInfo.promotionCode.isValid) {
        this.context.commit('REMOVE_FreeItems')
      }
      this.context.commit('SET_SingleOrder')
      if (this.signupInfo.promotionCode.isValid) {
        this.ApplyAnyApplicablePromotionCodes()
      }
    }
  }

  @Action
  ResetState() {
    this.context.commit('RESET_DefaultState', this.cleanState)
  }

  @Action
  async InitializeFromSessionState(state: string | SignupStateData) {
    this.context.commit('SET_ItemsAttachedToConfigs', false)
    this.context.commit('RESET_DefaultState', this.cleanState)
    let partialState: SignupStateData

    if ((state as SignupStateData).signupId === undefined) {
      partialState = JSON.parse(state as string)
    } else {
      partialState = state as SignupStateData
    }
    if (partialState.existingSubscriptionId && partialState.existingSubscriptionId !== '') {
      this.context.commit('SET_ExistingSubscriptionId', partialState.existingSubscriptionId)
    }
    const items = api.GetItems()
    const commonItemConfigurations = api.GetCommonItemConfigurations()
    let shippingResult: Promise<ShippingEligibiltyResult> | null
    let subscriberStatus: Promise<SubscriberStatus> | null

    if (
      partialState.signupInfo.customerContactInfo.emailAddress &&
      partialState.signupInfo.customerContactInfo.emailAddress.length > 0
    ) {
      subscriberStatus = api.GetUsernameStatus({ username: partialState.signupInfo.customerContactInfo.emailAddress })
      if (partialState.signupInfo.shippingAddress.address.postalCode) {
        shippingResult = api.ConfirmShippingInformation({
          emailAddress: partialState.signupInfo.customerContactInfo.emailAddress,
          postalCode: partialState.signupInfo.shippingAddress.address.postalCode
        })
      } else {
        shippingResult = api.GetEmptyShippingResult()
      }
    } else {
      shippingResult = api.GetEmptyShippingResult()
      subscriberStatus = api.GetEmptyUsername()
    }

    const results = await Promise.all([items, commonItemConfigurations, shippingResult, subscriberStatus])

    if (results[2].shippingEligiblity !== ShippingEligiblity.Unknown) {
      this.context.commit('SET_ShippingConfiguration', results[2])
    } else {
      this.context.commit('SET_InitialShippingConfiguration')
    }
    this.context.commit('SET_Items', results[0])
    this.context.commit('SET_CommonItemConfigurations', results[1])
    this.context.commit('SET_ConfigureCommonConfigItems')

    this.context.commit('SET_SubscriberStatus', results[3])
    this.context.commit('SET_CustomerContact', partialState.signupInfo.customerContactInfo)
    if (results[2].shippingEligiblity !== ShippingEligiblity.Unknown) {
      this.context.commit('SET_ShippingAddress', {
        streetAddressLine1: partialState.signupInfo.shippingAddress.address.streetAddressLine1,
        streetAddressLine2: partialState.signupInfo.shippingAddress.address.streetAddressLine2,
        city: partialState.signupInfo.shippingAddress.address.city,
        state: partialState.signupInfo.shippingAddress.address.state,
        postalCode: partialState.signupInfo.shippingAddress.address.postalCode,
        specialInstructions: partialState.signupInfo.shippingAddress.specialInstructions
      })
    }
    if (
      partialState.signupInfo.thirdPartyCredentials.provider &&
      partialState.signupInfo.thirdPartyCredentials.provider !== ''
    ) {
      this.SetThirdPartyCredentials(partialState.signupInfo.thirdPartyCredentials)
    }
    if (partialState.signupInfo.subscriptionConfiguration.packSize !== PackSize.Unknown) {
      this.SetPackSize(PackSize.Standard)
    }

    this.SetFrequency(partialState.signupInfo.subscriptionConfiguration.frequency)
    this.context.commit('SET_BillingAddress', {
      streetAddressLine1: partialState.signupInfo.billingContact.billingAddress.streetAddressLine1
        ? partialState.signupInfo.billingContact.billingAddress.streetAddressLine1
        : undefined,
      streetAddressLine2: partialState.signupInfo.billingContact.billingAddress.streetAddressLine2
        ? partialState.signupInfo.billingContact.billingAddress.streetAddressLine2
        : undefined,
      city: partialState.signupInfo.billingContact.billingAddress.city
        ? partialState.signupInfo.billingContact.billingAddress.city
        : '',
      state: partialState.signupInfo.billingContact.billingAddress.state
        ? partialState.signupInfo.billingContact.billingAddress.state
        : '',
      postalCode: partialState.signupInfo.billingContact.billingAddress.postalCode
        ? partialState.signupInfo.billingContact.billingAddress.postalCode
        : ''
    })

    this.context.commit('SET_BillingContact', {
      firstName: partialState.signupInfo.billingContact.firstName
        ? partialState.signupInfo.billingContact.firstName
        : '',
      lastName: partialState.signupInfo.billingContact.lastName ? partialState.signupInfo.billingContact.lastName : ''
    })

    if (
      partialState.selectedCommonItemConfiguration !== undefined &&
      partialState.selectedCommonItemConfiguration.id &&
      !validator.isEmpty(partialState.selectedCommonItemConfiguration.id)
    ) {
      await this.PurchaseCommonItemConfiguration(partialState.selectedCommonItemConfiguration.id)
    } else if (partialState.selectedProteins.length > 0) {
      await this.CustomizeCustomItemConfiguration(partialState.selectedProteins)
    }

    if (partialState.signupInfo.subscriptionConfiguration.packSize === PackSize.Large) {
      this.DoubleUp()
    }

    if (
      partialState.signupInfo.promotionCode.code &&
      !validator.isEmpty(partialState.signupInfo.promotionCode.code) &&
      !this.signupInfo.promotionCode.isValid
    ) {
      await this.AddPromotion(partialState.signupInfo.promotionCode.code)
    } else if (this.signupInfo.promotionCode.isValid) {
      await this.AddPromotion(this.signupInfo.promotionCode.code)
    }

    partialState.selectedItems.forEach(p => {
      const index = partialState.selectedItems.indexOf(p)
      if (this.SelectedItems[index] && this.SelectedItems[index].id !== p.id) {
        this.SubstituteItem({ itemIndex: index, newItemId: p.id })
      }
    })

    this.context.commit('SET_ShippingWeek', this.EligibleShippingWeeks[0])
    if (partialState.signupInfo.firstShipmentDate !== undefined) {
      const date = new Date(partialState.signupInfo.firstShipmentDate)
      this.EligibleShippingWeeks.forEach(x => {
        if (x.getDate() === date.getDate()) {
          this.context.commit('SET_ShippingWeek', this.EligibleShippingWeeks[this.EligibleShippingWeeks.indexOf(x)])
        }
      })
    }

    this.context.commit('SET_RestoredFromSessionState')
    this.context.commit('SET_ItemsAttachedToConfigs', true)
    this.context.commit('SET_OrderId', partialState.orderId)
    this.context.commit('SET_SignupId', partialState.signupId)
  }

  @Action({ rawError: true })
  async InitializeFromActiveCart(input: { cartId: string; promotionCode: string }): Promise<boolean> {
    const cart = await api.GetActiveCart(input.cartId)
    if (cart.signupId) {
      await this.InitializeFromSessionState(cart)
      if (input.promotionCode !== '') {
        await this.RemovePromotion()
        await this.AddPromotion(input.promotionCode)
      }
      return true
    }
    Cookie.remove('sc-cart')
    return false
  }

  @Action({ rawError: true })
  async Initialize() {
    // const shippingResult = api.ConfirmShippingInformationNoEmail(input);
    this.context.commit('SET_ItemsAttachedToConfigs', false)
    const items = api.GetItems()
    const commonItemConfigurations = api.GetCommonItemConfigurations()
    const subscriberStatus = SubscriberStatus.None
    const results = await Promise.all([
      items,
      commonItemConfigurations,
      // shippingResult,
      subscriberStatus
    ])
    this.context.commit('SET_SignupId', shortid.generate())
    await this.context.commit('SET_Items', results[0])
    await this.context.commit('SET_CommonItemConfigurations', results[1])
    await this.context.commit('SET_InitialShippingConfiguration')
    // this.context.commit('SET_ShippingConfiguration', results[2]);
    // this.context.commit('SET_ShippingAddress', results[2]);
    await this.context.commit('SET_ConfigureCommonConfigItems')
    await this.context.commit('SET_SubscriberStatus', results[2])
    await this.context.commit('SET_SingleOrder')
    await this.context.commit('SET_Frequency', Frequency.FourWeeks)
    // this.context.commit('SET_ShippingWeek', this.EligibleShippingWeeks[0]);
    if (this.signupInfo.promotionCode.isValid) {
      this.AddPromotion(this.signupInfo.promotionCode.code)
    }
    this.context.commit('SET_ItemsAttachedToConfigs', true)
  }

  @Action({ rawError: true })
  async GetReactivationInfo(subscriptionId: string) {
    const resubscriberInfo = await api.GetResubscribeInfo({ subscriptionId })
    this.context.commit('SET_ExistingSubscriptionId', subscriptionId)
    this.context.commit('SET_CustomerContact', { emailAddress: resubscriberInfo.customerContactInfo.emailAddress })
    await this.AddCustomerName({
      firstName: resubscriberInfo.customerContactInfo.firstName!,
      lastName: resubscriberInfo.customerContactInfo.lastName!
    })
    await this.AddCustomerMobilePhone(resubscriberInfo.customerContactInfo.phoneNumber!)
    await this.UpdateShippingPostalCode({
      postalCode: resubscriberInfo.shippingAddress.address.postalCode,
      overrideCity: resubscriberInfo.shippingAddress.address.city
    })

    await this.SetShippingAddress({
      streetAddressLine1: resubscriberInfo.shippingAddress.address.streetAddressLine1,
      streetAddressLine2: resubscriberInfo.shippingAddress.address.streetAddressLine2!,
      specialInstructions: resubscriberInfo.shippingAddress.specialInstructions!
    })
  }

  @Action
  async UpdateShippingPostalCode(input: { postalCode: string; overrideCity?: string }) {
    if (
      this.signupInfo.customerContactInfo.emailAddress &&
      validator.isEmail(this.signupInfo.customerContactInfo.emailAddress)
    ) {
      const shippingResult = await api.ConfirmShippingInformation({
        emailAddress: this.signupInfo.customerContactInfo.emailAddress,
        postalCode: input.postalCode
      })
      if (input.overrideCity) {
        shippingResult.city = input.overrideCity
      }
      this.context.commit('SET_ShippingAddress', shippingResult)
      if (shippingResult.shippingCenter !== this.shippingCenter) {
        this.context.commit('SET_ShippingConfiguration', shippingResult)
      }
      if (shippingResult.numberOfShippingDays !== this.numberOfShippingDays) {
        this.context.commit('SET_ShippingConfiguration', shippingResult)
        this.context.commit('SET_ShippingWeek', this.EligibleShippingWeeks[0])
      }
      if (this.signupInfo.firstShipmentDate === undefined) {
        this.context.commit('SET_ShippingWeek', this.EligibleShippingWeeks[0])
      }
    }
  }

  @Action
  UpdateShippingInfo(shippingResult: ShippingEligibiltyResult) {
    this.context.commit('SET_ShippingAddress', shippingResult)
    if (shippingResult.shippingCenter !== this.shippingCenter) {
      this.context.commit('SET_ShippingConfiguration', shippingResult)
    }
    if (shippingResult.numberOfShippingDays !== this.numberOfShippingDays) {
      this.context.commit('SET_ShippingConfiguration', shippingResult)
      this.context.commit('SET_ShippingWeek', this.EligibleShippingWeeks[0])
    }
    if (this.signupInfo.firstShipmentDate === undefined) {
      this.context.commit('SET_ShippingWeek', this.EligibleShippingWeeks[0])
    }
  }

  @Action
  async UpdateUsername(value: string): Promise<SubscriberStatus> {
    let username = ''
    if (value) {
      username = value.trim()
    }
    if (validator.isEmail(username) && username !== this.signupInfo.customerContactInfo.emailAddress) {
      const subscriberStatus = await api.GetUsernameStatus({ username })
      if (subscriberStatus === SubscriberStatus.None) {
        this.context.commit('SET_CustomerContact', { emailAddress: username })
      } else {
        this.context.commit('SET_CustomerContact', { emailAddress: username })
      }

      if (this.signupInfo.shippingAddress.address.postalCode) {
        const shippingResult = await api.ConfirmShippingInformation({
          emailAddress: username,
          postalCode: this.signupInfo.shippingAddress.address.postalCode
        })

        this.context.commit('SET_ShippingAddress', shippingResult)
        if (this.PurchasableCommonConfigurations.length === 0) {
          this.context.commit('SET_ShippingConfiguration', shippingResult)
        }
      }
      this.context.commit('SET_SubscriberStatus', subscriberStatus)
    } else if (validator.isEmpty(username)) {
      this.context.commit('REMOVE_EmailAddress')
      this.context.commit('SET_SubscriberStatus', SubscriberStatus.Unknown)
    }

    return this.subscriberStatus
  }

  @Action async AddCustomerMobilePhone(phone: string) {
    this.context.commit('SET_CustomerContact', {
      phoneNumber: phone
    })
  }

  @Action async AddCustomerName(input: { firstName: string; lastName: string }) {
    this.context.commit('SET_CustomerContact', {
      firstName: input.firstName,
      lastName: input.lastName
    })
  }

  @Action async SetShippingAddress(input: {
    streetAddressLine1: string
    streetAddressLine2: string
    specialInstructions: string
  }) {
    this.context.commit('SET_ShippingAddress', input)
  }

  @Action async SetBillingAddress(input: {
    streetAddressLine1: string
    streetAddressLine2: string
    city: string
    state: string
    postalCode: string
  }) {
    this.context.commit('SET_BillingAddress', input)
  }

  @Action async AddBillingContact(name: string) {
    const parsedFullName = parseFullName(name)
    this.context.commit('SET_BillingContact', { firstName: parsedFullName.first, lastName: parsedFullName.last })
  }

  @Action async ClearBillingAddress() {
    this.context.commit('REMOVE_BillingAddress')
  }

  @Action
  async RemovePromotion() {
    if (this.signupInfo?.promotionCode.isValid) {
      this.context.commit('REMOVE_FreeItems')
      this.context.commit('SET_PromotionCode', {
        code: '',
        isValid: false,
        dollarAmount: 0,
        discountDuration: 0,
        freeProducts: [],
        percentageAmount: 0,
        freeProductDuration: 0,
        messaging: ''
      })
    }
  }

  @Action async AddCustomerPassword(password: string) {
    this.context.commit('SET_Password', password)
  }

  @Action
  async AddPromotion(promotionCode: string) {
    const promoInfo = await api.ConfirmPromotionCode(promotionCode)

    if (promoInfo && promoInfo.isValid) {
      if (this.signupInfo?.promotionCode.isValid) {
        this.context.commit('REMOVE_FreeItems')
      }

      if (promoInfo.freeProducts) {
        promoInfo.freeProducts.forEach(q => {
          // eslint-disable-next-line no-param-reassign
          q.item = this.items.find(q1 => q1.id === q.id)
          if (q.subsituteItems) {
            q.subsituteItems.forEach(r => {
              // eslint-disable-next-line no-param-reassign
              r.item = this.items.find(r1 => r1.id === r.id)
            }, this)
          }
          if (q.item) {
            const packItem: LegacyPackItem = {
              freeItemId: q.freeItemId,
              displayOrder: this.selectedItems.length,
              item: { ...q.item },
              id: q.id,
              maxSwapPrice: q.maxSwapPrice,
              shippingQuantity: 1,
              subsituteItems: q.subsituteItems
            }
            this.context.commit('ADD_ItemSelection', packItem)
          }
        }, this)
      }
    }
    this.context.commit('SET_PromotionCode', promoInfo)

    if (promoInfo && promoInfo.isValid) return true
    Cookie.remove('scPC')
    return false
  }

  @Action
  async CustomizeCustomItemConfiguration(proteinList: Array<ProteinType>) {
    await this.SetSelectedProteins(proteinList)

    const result = await api.GetCustomItemConfiguration({
      proteinList: this.selectedProteins,
      shippingCenter: ShippingCenter.EastCoastCenter2
    })

    this.context.commit('REMOVE_SelectedCommonItemConfiugration')
    await this.SetSelectedItems(result.items)

    this.ApplyAnyApplicablePromotionCodes()

    if (this.signupInfo.subscriptionConfiguration.packSize === PackSize.Large) {
      if (this.signupInfo.promotionCode.isValid) {
        this.context.commit('REMOVE_FreeItems')
      }
      this.context.commit('SET_DoubleOrder')
      if (this.signupInfo.promotionCode.isValid) {
        this.ApplyAnyApplicablePromotionCodes()
      }
    }
  }

  @Action
  async PurchaseCommonItemConfiguration(id: string) {
    const selectedConfig = this.PurchasableCommonConfigurations.find(p => p.id === id)
    if (selectedConfig !== undefined) {
      this.context.commit('SET_SelectedCommonItemConfiguration', id)
      await this.SetSelectedProteins(selectedConfig.proteins)
      await this.SetSelectedItems(selectedConfig.items)
      if (selectedConfig.proteins.find(p => p === ProteinType.Fish)) {
        await this.SetShellfishAllergey(ShellfishCheck.NoAllergey)
      }
      this.ApplyAnyApplicablePromotionCodes()
    }
  }

  @Action
  private ApplyAnyApplicablePromotionCodes() {
    if (this.signupInfo && this.signupInfo.promotionCode) {
      if (this.signupInfo.promotionCode.freeProducts) {
        this.signupInfo.promotionCode.freeProducts.forEach(p => {
          if (p.item) {
            if (this.selectedItems.find(q => q.freeItemId === p.freeItemId) === undefined) {
              const packItem: LegacyPackItem = {
                freeItemId: p.freeItemId,
                displayOrder: p.displayOrder,
                item: { ...p.item },
                id: p.item.id,
                maxSwapPrice: p.maxSwapPrice,
                shippingQuantity: 1,
                subsituteItems: p.subsituteItems
              }
              this.context.commit('ADD_ItemSelection', packItem)
            }
          }
        })
      }
    }
  }

  @Action
  ProcessSignupError() {
    if (this.shippingEligiblity === ShippingEligiblity.Unknown) {
      this.context.commit(
        'SET_ErrorMessage',
        `Sorry, the zip code: ${this.signupInfo.shippingAddress.address.postalCode} is invalid.`
      )
    } else if (this.shippingEligiblity === ShippingEligiblity.Ineligible) {
      this.context.commit(
        'SET_ErrorMessage',
        `Sorry, we don't ship to ${this.signupInfo.shippingAddress.address.city}
      ${this.signupInfo.shippingAddress.address.state}`
      )
    } else {
      switch (this.subscriberStatus) {
        case SubscriberStatus.Active:
          this.context.commit(
            'SET_ErrorMessage',
            `You already have an active account with the email address ${this.signupInfo.customerContactInfo.emailAddress}.`
          )
          break
        case SubscriberStatus.Canceled:
          this.context.commit(
            'SET_ErrorMessage',
            'Welcome back, to reactivate your account you will need to login to your account.'
          )
          break
        case SubscriberStatus.Suspended:
          this.context.commit(
            'SET_ErrorMessage',
            'You need to update your billing information, you will ned to login to your account.'
          )
          break
        default:
          break
      }
    }
  }

  @Action
  ClearErrorMessage() {
    this.context.commit('SET_ErrorMessage', '')
  }

  @Action
  SetPackSize(packSize: PackSize) {
    this.context.commit('SET_PackSize', packSize)
  }

  @Action
  SetThirdPartyCredentials(input: { id: string; provider: string }) {
    this.context.commit('SET_ThirdpPartyCredentials', input)
  }

  @Action
  SetFrequency(frequency: Frequency) {
    this.context.commit('SET_Frequency', frequency)
  }

  @Action
  // eslint-disable-next-line class-methods-use-this
  async SignupSubscriber(token: string): Promise<SignupResult> {
    const result = await api.ProcessSignup({
      customerContactInfo: this.signupInfo.customerContactInfo,
      subscriptionConfiguration: {
        frequency: this.signupInfo.subscriptionConfiguration.frequency,
        packSize: this.signupInfo.subscriptionConfiguration.packSize,
        proteinTypes: this.selectedProteins
      },
      shippingAddress: this.signupInfo.shippingAddress,
      billingInformation: this.signupInfo.billingContact,
      firstShipmentDate: this.signupInfo.firstShipmentDate!,
      overrideShippingAddress: this.signupInfo.overrideShippingAddress === true,
      password: this.signupInfo.password!,
      thirdPartyProvider: this.signupInfo.thirdPartyCredentials.provider,
      thirdPartyId: this.signupInfo.thirdPartyCredentials.id,
      promotionCode: this.signupInfo.promotionCode.isValid ? this.signupInfo.promotionCode.code : undefined,
      selectedItems: this.SelecteItemsMinusAnyFree.map(p => ({ itemId: p.id, maxSwapPrice: p.maxSwapPrice })),
      stripeToken: token,
      originalSubscriptionId: this.existingSubscriptionId
    })
    if (result.errorMessage) {
      this.context.commit('SET_ErrorMessage', result.errorMessage)
    } else {
      this.context.commit('SET_OrderId', result.orderId)
      if (!this.IsCommonItemConfigurationSelected) {
        LogRocket.track('BuldYourOwn')
      } else if (this.hasCustomizedCommonConfiguration) {
        LogRocket.track('SwappedItems')
      } else {
        LogRocket.track('CommonPack')
      }
      if (this.hasValidPromotionCode) {
        LogRocket.track(this.signupInfo.promotionCode.code)
      }
      LogRocket.identify(this.signupInfo.customerContactInfo.emailAddress, {
        name: `${this.signupInfo.customerContactInfo.firstName} ${this.signupInfo.customerContactInfo.lastName}`
      })
      this.context.commit('SET_OrderComplete')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (process.env.VUE_APP_TRACKING! === 'YES') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const w2 = window as any
        w2.AddShoppersConversion.order_id = this.orderId
        w2.AddShoppersConversion.value = this.finalPrice
        w2.AddShoppersWidget.track_conv()
        w2.transaction_id = this.orderId
        w2.purchase_price = this.finalPrice
        w2.purchase_state = this.signupInfo.shippingAddress.address.state
        w2.parent_sku =
          this.signupInfo.subscriptionConfiguration.packSize === PackSize.Large ? 'STEAKCHOP-LRG' : 'STEAKCHOP-RGL'
        w2.variant_sku = this.PackId
      }
    }
    return result
  }
}
