import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import VueGtm from 'vue-gtm'
import Cookie from 'js-cookie'
import m from 'moment'

Vue.use(VueRouter)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const gtm = Vue as any

const routes: Array<RouteConfig> = [
  {
    props: true,
    path: '/shopping/welcome/:promotionCode?',
    name: 'signup.welcome',
    beforeEnter: (to: Route) => {
      if (to.params.promotionCode) {
        const expiry = m(Date.now())
          .add(30, 'days')
          .toDate()
        Cookie.set('scPC', to.params.promotionCode, { expires: expiry })
      }
      const path = to.fullPath.split('?')

      if (path.length > 1) {
        window.location.href = `/?${path[1]}`
      } else {
        window.location.href = '/'
      }
    }
  },
  {
    path: '/e-gift-purchase',
    name: 'e-gift-purchase',
    component: () => import(/* webpackChunkName: "e-gift-purchase" */ '@/views/EGiftPurchase.vue'),
    children: [
      {
        props: true,
        name: 'e-gift-purchase.start',
        path: 'start/:contactId?',
        component: () =>
          import(/* webpackChunkName: "e-gift-purchase" */ '@/components/gift-purchase-e-gift/start/index.vue')
      },
      {
        props: false,
        name: 'e-gift-purchase.details',
        path: 'details',
        component: () =>
          import(/* webpackChunkName: "e-gift-purchase" */ '@/components/gift-purchase-e-gift/details/index.vue')
      },
      {
        props: false,
        name: 'e-gift-purchase.complete',
        path: 'complete',
        component: () =>
          import(/* webpackChunkName: "e-gift-purchase" */ '@/components/gift-purchase-e-gift/complete/index.vue')
      }
    ]
  },
  {
    path: '/gift-redemption',
    name: 'gift-redemption',
    component: () => import(/* webpackChunkName: "gift-redemption" */ '@/views/GiftRedemption.vue'),
    children: [
      {
        props: true,
        name: 'gift-redemption.start',
        path: 'start/:giftCardId?',
        component: () =>
          import(/* webpackChunkName: "gift-redemption" */ '@/components/gift-redemption/start/index.vue')
      },
      {
        props: true,
        name: 'gift-redemption.redeem',
        path: 'redeem/:giftCardId',
        component: () =>
          import(/* webpackChunkName: "gift-redemption" */ '@/components/gift-redemption/start/index.vue')
      },
      {
        props: true,
        name: 'gift-redemption.customize',
        path: 'customize',
        component: () =>
          import(/* webpackChunkName: "gift-redemption" */ '@/components/gift-redemption/customize/index.vue')
      },
      {
        props: true,
        name: 'gift-redemption.pick-proteins',
        path: 'pick-proteins',
        component: () =>
          import(/* webpackChunkName: "gift-redemption" */ '@/components/gift-redemption/pick-proteins/index.vue')
      },
      {
        props: true,
        name: 'gift-redemption.pick-your-gift',
        path: 'pick-your-gift',
        component: () =>
          import(/* webpackChunkName: "gift-redemption" */ '@/components/gift-redemption/pick-gift-pack/index.vue')
      },
      {
        props: true,
        name: 'gift-redemption.confirm',
        path: 'confirm',
        component: () =>
          import(/* webpackChunkName: "gift-redemption" */ '@/components/gift-redemption/confirm/index.vue')
      },
      {
        props: true,
        name: 'gift-redemption.complete',
        path: 'complete',
        component: () =>
          import(/* webpackChunkName: "gift-redemption" */ '@/components/gift-redemption/complete/index.vue')
      }
    ]
  },
  {
    path: '/quick-access/:contactId/',
    name: 'quick-access',
    props: true,
    component: () => import(/* webpackChunkName: "quick-access" */ '@/views/QuickAccess.vue'),
    children: [
      {
        props: true,
        name: 'quick-access.byo.start',
        path: 'byo',
        component: () => import(/* webpackChunkName: "quick-access" */ '@/components/quick-access/byo/start/index.vue')
      },
      {
        props: true,
        name: 'quick-access.byo.customize',
        path: 'byo-customize',
        component: () =>
          import(/* webpackChunkName: "quick-access" */ '@/components/quick-access/byo/customize/index.vue')
      },
      {
        props: true,
        name: 'quick-access.byo.pending',
        path: 'byo-pending',
        component: () =>
          import(/* webpackChunkName: "quick-access" */ '@/components/quick-access/byo/pending/index.vue')
      },
      {
        props: true,
        name: 'quick-access.byo.pick-proteins',
        path: 'byo-pick-proteins',
        component: () =>
          import(/* webpackChunkName: "quick-access" */ '@/components/quick-access/byo/pick-proteins/index.vue')
      },
      {
        props: true,
        name: 'quick-access.byo.unboxed',
        path: 'byo-unboxed',
        component: () =>
          import(/* webpackChunkName: "quick-access" */ '@/components/quick-access/byo/unboxed/index.vue')
      },
      {
        props: true,
        name: 'quick-access.byo.confirm',
        path: 'byo-confirm',
        component: () =>
          import(/* webpackChunkName: "quick-access" */ '@/components/quick-access/byo/confirm/index.vue')
      }
    ]
  },
  {
    path: '/shopping',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/Signup.vue'),

    children: [
      {
        props: true,
        name: 'signup.reactivate',
        path: 'reactivate/:subscriptionId/:giftCustomer/:shouldResume/:promotionCode?',
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/Resubscribe/index.vue')
      },
      {
        props: true,
        name: 'signup.resume-cart',
        path: 'resume/:promotionCode?',
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/Resume/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          if (from.path.startsWith('/shopping/pick-pack')) {
            // eslint-disable-next-line no-param-reassign
            to.params.goingBack = 'true'
          } else {
            // eslint-disable-next-line no-param-reassign
            to.params.goingBack = 'false'
            if (from.params.packType) {
              // eslint-disable-next-line no-param-reassign
              to.params.packType = from.params.packType
            }
            if (from.params.customize) {
              // eslint-disable-next-line no-param-reassign
              to.params.customize = from.params.customize
            }
          }
          next()
        }
      },
      {
        props: true,
        name: 'signup.resume',
        path: 'resume-cart/:cartId/:promotionCode?',
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/Resume/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          if (from.path.startsWith('/shopping/pick-pack')) {
            // eslint-disable-next-line no-param-reassign
            to.params.goingBack = 'true'
          } else {
            // eslint-disable-next-line no-param-reassign
            to.params.goingBack = 'false'
            if (from.params.packType) {
              // eslint-disable-next-line no-param-reassign
              to.params.packType = from.params.packType
            }
            if (from.params.customize) {
              // eslint-disable-next-line no-param-reassign
              to.params.customize = from.params.customize
            }
          }
          next()
        }
      },
      {
        props: true,
        name: 'signup.lets-go',
        path: 'lets-go/:packType/:customize',
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/Initialize/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          if (from.path.startsWith('/shopping/resume')) {
            // eslint-disable-next-line no-param-reassign
            to.params.goingBack = 'true'
          } else {
            // eslint-disable-next-line no-param-reassign
            to.params.goingBack = 'false'
          }
          next()
        }
      },
      {
        props: true,
        name: 'signup.getting-started',
        path: 'getting-started/:emailAddress?/:zipCode?/:promotionCode?/:proteinList?/:size?/:frequency?',
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/Initialize/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          if (from.path.startsWith('/shopping/resume')) {
            // eslint-disable-next-line no-param-reassign
            to.params.goingBack = 'true'
          } else {
            // eslint-disable-next-line no-param-reassign
            to.params.goingBack = 'false'
          }
          next()
        }
      },
      {
        props: true,
        path: 'pick-pack/:personalize?/:pickproteins?/:cameFromCheckout?',
        name: 'signup.pick-pack',
        meta: {
          title: 'SteakChop - Select a Pack'
        },
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/PickYourPack/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          gtm.gtm.trackEvent({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: { step: 2 }
              }
            }
          })
          next()
        }
      },
      {
        props: true,
        path: 'details',
        name: 'signup.details',
        meta: {
          title: 'SteakChop - Size and Frequency'
        },
        component: () =>
          import(/* webpackChunkName: "signup" */ '@/components/signup/BasicsSizeAndFrequency/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          gtm.gtm.trackEvent({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: { step: 3 }
              }
            }
          })
          next()
        }
      },
      {
        props: true,
        path: 'contact/:packType?/:customize?',
        name: 'signup.contact',
        meta: {
          title: "SteakChop - Let's Get Started"
        },
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/BasicsEmailOnly/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          gtm.gtm.trackEvent({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: { step: 1 }
              }
            }
          })
          next()
        }
      },
      {
        name: 'signup.checkout',
        path: 'checkout',
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/Checkout/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          gtm.gtm.trackEvent({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: { step: 4 }
              }
            }
          })
          next()
        },
        meta: {
          title: 'SteakChop - Checkout'
        }
      },
      {
        name: 'signup.receipt',
        path: 'receipt',
        component: () => import(/* webpackChunkName: "signup" */ '@/components/signup/Receipt/index.vue'),
        beforeEnter: (to: Route, from: Route, next: any) => {
          gtm.gtm.trackEvent({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: { step: 5 }
              }
            }
          })
          next()
        },
        meta: {
          title: 'SteakChop - Receipt'
        }
      }
    ]
  },
  {
    path: '/PerfectPack/',
    beforeEnter: () => {
      window.location.href = '/'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 })
})

Vue.use(VueGtm, {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: process.env.VUE_APP_GTM_KEY!,
  enabled: true,
  debug: false,
  loadScript: false
})

export default router
