import { Store } from 'vuex'
import validator from 'validator'
import { SignupStateData } from '@/store/modules/signup/types'
import { LegacyPackItem, SubstituteItem } from '@/modules/common'
import sortBy from 'lodash/sortBy'
import * as api from '@/apis/signup'
import Cookie from 'js-cookie'
import { SignupStatus } from '@/modules/signup'
import m from 'moment'

function SaveShoppingState(state: any) {
  if (state.signup.signupId && !validator.isEmpty(state.signup.signupId)) {
    const selectedItems = sortBy(state.signup.selectedItems as Array<LegacyPackItem>, [
      o => (o.freeItemId ? 1 : 0),
      'displayOrder'
    ])

    const signupState: SignupStateData = {
      numberOfShippingDays: state.signup.numberOfShippingDays,
      shellfishAlergyStatus: state.signup.shellfishAlergyStatus,
      shippingCenter: state.signup.shippingCenter,
      shippingEligiblity: state.signup.shippingEligiblity,
      signupStatus: state.signup.signupStatus,
      signupId: state.signup.signupId,
      existingSubscriptionId: state.signup.existingSubscriptionId,
      orderId: state.orderId,
      signupInfo: state.signup.signupInfo,
      subscriberStatus: state.signup.subscriberStatus,
      selectedCommonItemConfiguration: {
        id: state.signup.selectedCommonItemConfiguration.id,
        shippingCenter: 0,
        displayOrder: 0,
        description: '',
        items: [],
        isReadonly: false,
        name: '',
        proteins: []
      },
      errorMessage: '',
      selectedProteins: state.signup.selectedProteins,
      commonItemConfigurations: [],
      selectedItems: selectedItems.map(
        p =>
          ({
            displayOrder: p.displayOrder,
            id: p.id,
            freeItemId: p.freeItemId,
            item: undefined,
            subsituteItems: p.subsituteItems.map(
              q =>
                ({
                  displayOrder: q.displayOrder,
                  id: q.id,
                  item: undefined
                } as SubstituteItem)
            )
          } as LegacyPackItem)
      )
    }

    const s = JSON.stringify(signupState)

    sessionStorage.setItem('signup-steakchop', s)

    const hashCode = s.split('').reduce((a: number, b: string) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0) // eslint-disable-line no-bitwise
    if (hashCode !== state.lastCartHash) {
      if (
        signupState.signupStatus !== SignupStatus.Completed &&
        signupState.errorMessage === '' &&
        signupState.signupInfo.customerContactInfo.emailAddress !== ''
      ) {
        api.SaveActiveCart({
          cartId: signupState.signupId,
          emailAddress: signupState.signupInfo.customerContactInfo.emailAddress,
          timestamp: Date.now(),
          cartPayload: s
        })
      }
      state.lastCartHash = hashCode
      const expiry = m(Date.now())
        .add(30, 'days')
        .toDate()
      Cookie.set('sc-cart', signupState.signupId, { expires: expiry })
    }
    if (signupState.signupStatus === SignupStatus.Completed) {
      sessionStorage.removeItem('signup-steakchop')
      Cookie.remove('scPC')
      Cookie.remove('sc-cart')
      api.RemoveCompletedCart({
        cartId: signupState.signupId,
        emailAddress: signupState.signupInfo.customerContactInfo.emailAddress
      })
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (store: Store<any>) => {
  store.subscribe((mutation, state) => {
    SaveShoppingState(state)
  })
}
