import {
  VuexModule, Module, MutationAction
} from 'vuex-module-decorators'
import store from '@/store'
import { CommonStateData } from './types'

@Module({
  dynamic: true, store, name: 'common'
})
export default class CommonModule extends VuexModule implements CommonStateData {
    showOverlay = false;

    quickAccessTitle = '';

    get DisplayOverlay() {
      return this.showOverlay
    }

    @MutationAction({ mutate: ['showOverlay'] })
    // eslint-disable-next-line class-methods-use-this
    async HideOverlay() {
      return { showOverlay: false }
    }

    @MutationAction({ mutate: ['showOverlay'] })
    // eslint-disable-next-line class-methods-use-this
    async ShowOverlay() {
      return { showOverlay: true }
    }

    @MutationAction({ mutate: ['quickAccessTitle'] })
    // eslint-disable-next-line class-methods-use-this
    async SetQuickActionTitle(title: string) {
      return { quickAccessTitle: title }
    }
}
