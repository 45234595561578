import { ProductConfigurationTemplate, ProductConfigurationTemplateType } from '../byo'

export interface Contact {
  firstName: string | undefined
  lastName: string | undefined
  emailAddress: string
  phoneNumber: string | undefined
}

export interface Address {
  streetAddressLine1: string
  streetAddressLine2: string | undefined
  city: string
  state: string
  postalCode: string
}

export interface ShippingAddress {
  specialInstructions: string | undefined
  address: Address
}

export enum PackSize {
  Unknown = 0,
  Standard = 1,
  Large = 2
}

export enum Frequency {
  Unknown = 0,
  TwoWeeks = 2,
  FourWeeks = 4,
  SixWeeks = 6,
  EightWeeks = 8,
  TenWeeks = 10,
  TwelveWeeks = 12,
  SixteenWeeks = 16
}

export enum ProteinType {
  Unknown = 0,
  Beef = 1,
  Poultry = 2,
  Pork = 3,
  Fish = 4,
  Shellfish = 5
}

export interface SubscriptionConfiguration {
  proteinTypes: ProteinType[]
  frequency: Frequency
  packSize: PackSize
}

export interface BillingInformation {
  firstName: string
  lastName: string
  billingAddress: Address
}

export enum ShippingCenter {
  Unknown = 0,
  EastCoastCenter1 = 1,
  WestCoastCenter1 = 2,
  EastCoastCenter2 = 3,
  EastCoastCenter3 = 4
}

export enum SubscriberStatus {
  Unknown = 0,
  None = 1,
  Active = 2,
  Canceled = 3,
  Suspended = 4
}

export enum SubscriptionStatus {
  Unknown = 0,
  Active = 1,
  Canceled = 2,
  Suspended = 3,
  Paused = 4,
  Gift = 5
}

export enum ShippingEligiblity {
  Unknown = 0,
  Eligible = 1,
  Ineligible = 2
}

export interface ShippingEligibiltyResult {
  shippingEligiblity: ShippingEligiblity
  shippingCenter: ShippingCenter
  numberOfShippingDays: number
  city: string
  state: string
  postalCode: string
  shippingWeeks: Array<string>
}

export interface SignupResult {
  orderId: string
  errorMessage: string
}

export interface ItemAvailiblity {
  fulfillmentCenterId: number
  isAvailable: boolean
  price: number
}
export interface Item {
  id: string
  name: string
  marketingDescription: string
  marketingDescriptionShort: string
  size: string
  quantity: number
  proteinType: ProteinType
  calcWeight: number
  itemAvailabilities: Array<ItemAvailiblity>
}

export interface SubstituteItem {
  displayOrder: number
  id: string
  surcharge?: number
  item: Item | undefined
}

export interface PackItem {
  displayOrder: number
  id: string
  item: Item | undefined
  subsituteItems: Array<SubstituteItem>
  shippingQuantity: number
}

export interface LegacyPackItem extends PackItem {
  freeItemId: string
  maxSwapPrice: number
}
export interface CommonItemConfiguration {
  id: string
  shippingCenter: ShippingCenter
  name: string
  description: string
  displayOrder: number
  items: Array<LegacyPackItem>
  proteins: Array<ProteinType>
  isReadonly: boolean
  templateType?: ProductConfigurationTemplateType
  priceOverride?: number
}

export interface ItemTemplateConfiguration {
  id: string
  shippingCenter: ShippingCenter
  name: string
  description: string
  activeTemplate: ProductConfigurationTemplate
  isReadonly: boolean
  surcharge: number
  discount: number
  listPrice: number
}

export interface CustomItemConfiguration {
  id: string
  shippingCenter: ShippingCenter
  items: Array<LegacyPackItem>
  proteins: Array<ProteinType>
}

export enum ShellfishCheck {
  Unknown = 0,
  NoAllergey = 1,
  Allergy = 2
}
