import Vue from 'vue'
import Vuex from 'vuex'
import vmask from 'v-mask'
import shoppingCartPlugin from '@/plugins/shoppingCart'
import VueScrollTo from 'vue-scrollto'

Vue.use(Vuex)
Vue.use(vmask)
Vue.use(VueScrollTo)
const store = new Vuex.Store({ plugins: [shoppingCartPlugin] })

export default store
