




















import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import { Watch } from 'vue-property-decorator'
import Cookie from 'js-cookie'

@Component({
  components: {}
})
export default class DiscountBanner extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  toggle = false;

  @Watch('promotionText')
  updatedPromotionText(newValue: string) {
    this.toggle = newValue !== '' && newValue !== undefined
  }

  @Watch('toggle')
  updatedToggle(newValue: boolean) {
    if (newValue) {
      this.$emit('promotion-banner-visible')
    }
  }

  processHidden() {
    Cookie.set('bsup', 'y')
    this.$emit('promotion-banner-hidden')
  }

  get isValid() {
    return this.signUpModule.signupInfo.promotionCode &&
    this.signUpModule.signupInfo.promotionCode.isValid
  }

  get promotionText(): string {
    if (!Cookie.get('bsup')) {
      if (!this.hasUrgency && this.$vuetify.breakpoint.smAndUp &&
      this.signUpModule.signupInfo.promotionCode.isValid &&
      this.signUpModule.signupInfo.promotionCode.messaging) {
        return `${this.signUpModule.signupInfo.promotionCode.messaging}`
      }
      if (this.hasUrgency && this.$vuetify.breakpoint.xs &&
      this.signUpModule.signupInfo.promotionCode.isValid &&
      this.signUpModule.signupInfo.promotionCode.messaging) {
        return this.signUpModule.signupInfo.promotionCode.messaging.replace(' Expires Today!', '')
      }
      if (this.hasUrgency && this.$vuetify.breakpoint.smAndUp &&
      this.signUpModule.signupInfo.promotionCode.isValid &&
      this.signUpModule.signupInfo.promotionCode.messaging) {
        return `${this.signUpModule.signupInfo.promotionCode.messaging}`
      }
      return this.signUpModule.signupInfo.promotionCode.messaging
    }
    return ''
  }

  get hasUrgency(): boolean {
    return this.signUpModule.signupInfo.promotionCode &&
    this.signUpModule.signupInfo.promotionCode.code.endsWith('U')
  }
}
