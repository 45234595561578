

































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {}
})
export default class StepNavigation extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  getClassesForStep(stepName: string) {
    if (
      this.$route.name === stepName ||
      (this.$route.name === 'signup.receipt' && stepName === 'signup.checkout')
    ) {
      return ['primary', 'circle', 'white--text', 'body-2']
    }

    return ['circle', 'body-2']
  }

  isCheckoutClickable() {
    return this.$route.name === 'signup.details'
  }

  isContactClickable() {
    return (
      this.$route.name === 'signup.checkout' ||
      this.$route.name === 'signup.pick-pack' ||
      this.$route.name === 'signup.details'
    )
  }

  isBasicsClickable() {
    return (
      this.$route.name === 'signup.checkout'
    )
  }

  isPickPackClickable() {
    return (
      this.$route.name === 'signup.checkout' ||
      this.$route.name === 'signup.details'
    )
  }

  get canShow() {
    switch (this.$route.name) {
      case 'signup.contact':
      case 'signup.details':
      case 'signup.pick-pack':
      case 'signup.checkout':
        return true
      default:
        return false
    }
  }

  basicsHandler() {
    this.$router.push('/shopping/details')
  }

  contactHandler() {
    this.$router.push('/shopping/contact')
  }

  checkoutHandler() {
    this.$router.push('/shopping/checkout')
  }

  pickPackHandler() {
    if (
      this.signUpModule.IsCommonItemConfigurationSelected &&
      this.signUpModule.hasCustomizedCommonConfiguration
    ) {
      this.$router.push('/shopping/pick-pack/true')
    } else if (this.signUpModule.IsCommonItemConfigurationSelected === false) {
      this.$router.push('/shopping/pick-pack/true')
    } else {
      this.$router.push('/shopping/pick-pack')
    }
  }
}
